@use 'vars' as *;
@use 'utilities';
@use 'home';
@use 'page';
@use 'about_us';
@use 'services';
@use 'products';

html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    box-sizing: border-box;
    font-size: 14px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $primary-color;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
    font-size:12px;
    scroll-behavior: smooth;
}

.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 20px;
}

header .contentwrap{
    padding:  0px;
}

img {
    border:none;
}
a.logo {
    display: inline-block;
    width: 218px;
    height: 133px;
    position: relative;
    img{
        max-width: 238px;
        position: absolute;
        top:20px;
    }
}

/** menu generic **/
nav ul {
    list-style:none;
    padding: 0;
    margin: 0;
}
nav li {
    display: inline-block;
    box-sizing: border-box;
}
nav a {
    display: block;
    color:inherit;
    text-decoration: none;
}
header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}

/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 5px 0;
}
label.float,
input {
    height: 45px;
    line-height: 45px;
    font-size: 18px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-size: 18px;
    width: 100%;
    box-sizing: border-box;
}
textarea {
    min-height: 80px;
}

form button {
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}

/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
}

/*============ header ============*/
.header-menu{
    display:flex;
}

.menu-wrapper{
    display:flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 2;
    font-weight: 600;
    padding-left:238px;
    & > ul{
        display:flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: space-between;
        li{
            width: auto;
        }
    }
    .site-links{
        display:flex;
        a{
            margin-left:.75rem;
            .social-link-icon{
           
                transition: all .2s ease-in-out;
                transform: scale(1);
                &:hover{
                    transform: scale(1.25);
                }
                
            }
        }
       
    }
}

header.top {
    font-size:14px;
    background-color: rgba(21, 21 , 21, .5);
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 100;
}
.header-bar-top {
    color:#ffffff;
    background-size:cover;
}
.header-social-btns{
    margin:0 1rem;
    font-weight: 400;
}



header.top li {
    vertical-align:top;
}
// .header-menu {
//     line-height:130px;
// }
.header-menu > ul {
    text-align:right;
}
.header-menu li {
    margin:20px;
    font-size: 16px;
    font-weight: 400;
}
.header-bar-bottom {
    background:#333333;
    color:#fff;
    border-top:1px solid #222222;
    position:relative;
}
.main-menu ul {
    text-align:justify;
    line-height:0;
}
.main-menu ul::after {
    content:"";
    display:inline-block;
    width:100%;
    line-height:0px;
    vertical-align:top;
}
.main-menu li {
    margin:5px;
    line-height:1;
}
.main-menu li.depth-0 {
    vertical-align:middle;
}

.main-menu a {
    padding:15px 0;
}
.main-menu li > ul {
    position:absolute;
    top: 100%;
    left:0;
    right:0;
    background:#f6f6f6;
    color:#082133;
    z-index:10000;
    text-align: left;
    display:none;
}
.main-menu li:hover > ul {
    display:block;
}
.main-menu li > ul::before,
.main-menu li > ul::after {
    content:"";
    background:#f6f6f6;
    position:absolute;
    top:0;
    bottom:0;
    width:0;
    height:100%;
}
.main-menu li > ul::before {
    left:0;
    padding-left:50vw;
    margin-left:-50vw;
    background:#f6f6f6;
}
.main-menu li > ul::after {
    right:0;
    padding-right:50vw;
    margin-right:-50vw;
    background:#f6f6f6;
}
.main-menu .depth-1 .title {
    font-size:18px;
}
.main-menu .depth-1 a {
    width: 160px;
    margin: 30px;
}

#mobile-menu {
    display: none;
    position: absolute;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index:100;
}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #000;
    margin: 6px 0;
    transition: all 0.5s ease;
}
.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.slide-menu-active #mobile-menu span.three {
     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}

/***** Slide menu ****/
.slide-menu {
    display:none;
}
.slide-menu {
	background: #fff;
    width:240px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -240px;
	overflow-y:scroll;
}
.slide-menu-active .slide-menu {
    right: 0px;
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu .menu-title {
	padding: 1em;
	color:#fff;
	background:$primary-color;
	margin:0;
	font-weight:bold;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu ul ul {
	background:#eee;
}
.slide-menu ul ul ul{
	background:#ddd;
}
.slide-menu li {
    position:relative;
    display: block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a {
	display: block;
	color: #000;
	font-size: 16px;
	font-weight: 300;
	border-bottom: 1px solid #030b16;
	padding: 18px;
	text-decoration:none;
    font-weight: bold;
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px; /* space for drop */
}
.slide-menu a:hover {
	background: $primary-color;
    color:white;
}
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    // border-left: 1px solid #030b16;
    height: 58px;
    width: 50px;
    cursor: pointer;
}
.slide-menu ul .expand i {
    filter: invert(1);
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
/*======== Splash section=========*/
.splash{
    position: relative;
    // background-color: $primary-color;
    min-height: 500px;
    height: 100vw;
    @media screen and (min-width:1200px) {
        max-height: 100vh;
   
    }
    .absolute{
        img{
            position: absolute;
            inset: 0;
        }
    }
    img, #youtube-video{
        min-height: 500px;
        height: 100%!important;
        width: 100%;
        object-fit: cover;
        mix-blend-mode: multiply;
        iframe{
            max-width: none;
        }
    }
    img{
        background-color:$primary-color;
    }
    .filler{
        background-color:$primary-color; 
        height: 100%;
        width: 100%;
    }
    .overlay{
 
        position: absolute;
        inset: 0;
        z-index: 10;
        .content{
            .title{
                font-size: 22px;
                font-weight: 400;
                margin-bottom: 1.5rem;
                text-transform: uppercase;
                @media screen and (max-width: 996px) {
                    font-size: 18px;
             
                }
                @media screen and (max-width: 576px) {
                    font-size: 16px;
                }
            }
            .subtitle{
                font-size: 62px;
                font-weight:600;
                max-width: 840px;
                margin-top:0;
                margin-bottom: 2rem;
                line-height: 1.2;
                @media screen and (max-width: 996px) {
                    font-size: 7vw;
             
                }
                @media screen and (max-width: 576px) {
                    font-size: 32px;
                }
                @media screen and (max-width: 300px) {
                    font-size: 24px;
                }
            }
            .summary{
                font-size: 18px;
                font-weight:400;
                max-width: 610px;
                margin-bottom: 2rem;
                @media screen and (max-width: 576px) {
                    font-size: 14px;
                }
            }
            
        }
       
    }
}
/*======== Brand section=========*/
.brands{
    padding:6rem 2rem;
    text-align: center;
    @media screen and (max-width: 576px) {
        padding:3rem 2rem;
    }
    .title{
        margin-top: 0;
        margin-bottom: 3rem;
        @media screen and (max-width: 576px) {
            margin-bottom: 1rem;
        }
    }
    .banners-brands{
        .banner-stage{
            display: flex;
            justify-content: center;
            align-items: stretch;
            @media screen and (max-width: 576px) {
                flex-wrap: wrap;
            }
            a{
                display: flex;
                align-items: center;
                padding:0 2rem;
                border-right:1px solid white;
                &:last-child{
                    border-right: none;
                }
                @media screen and (max-width: 576px) {
                    border:none;
                    margin:1rem;
                }
            }
            .banner-name{display: none;}
        }
    }
}
/*======== Brand section=========*/
.services{
    padding:4rem 0;
    text-align: center;
    @media screen and (max-width: 576px) {
        padding:2rem 0;
    }
    .title{
        font-weight: 700;
        font-size: 52px;
        @media screen and (max-width: 768px) {
            font-size: 7vw;
        }
        @media screen and (max-width: 576px) {
            font-size: 32px;
        }
        @media screen and (max-width: 300px) {
            font-size: 24px;
        }
    }
    .three-col-grid{
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(3, 1fr);
        @media screen and (max-width: 992px) {
            grid-template-columns: 1fr;
       
        }
    }
    .two-col-grid{
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(2, 1fr);
        @media screen and (max-width: 992px) {
            grid-template-columns: 1fr;
       
        }
    }
       
    .card{
        position: relative;
        background-color: rgba(21, 21, 21, .20);
        overflow: hidden;
        img{
            height: 100%!important;
            width: 100%!important;
            object-fit: cover;
            mix-blend-mode: multiply;
            transition: all 1s ease-in-out;
        }
        
        .overlay{
            filter: drop-shadow(0px 0px 0px transparent);
            transition: all 1s ease-in-out;
            position: absolute;
            inset:0;
            color:white;
            font-size: 40px;
            font-weight: 500;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 576px) {
                font-size: 32px;
            }
            @media screen and (max-width: 300px) {
                font-size: 18px;
            }
        }
        &:hover{
            img{
                transform: scale(1.2);
            }  
            .overlay{
                filter: drop-shadow(0px 0px 0px rgba(0,0,0,0.9));
            }
        }
    }
}
.contact-form{
    padding:8rem 2rem;
    @media screen and (max-width: 992px) {
        padding:6rem 2rem;
    }
    @media screen and (max-width: 576px) {
        padding:2rem 2rem;
    }
    .title{
        font-weight: 700;
        font-size: 22px;
        text-transform: uppercase;
        @media screen and (max-width: 576px) {
            font-size: 16px;
        }
    }
    .text{
        font-weight:300;
        font-size: 26px;
        margin-bottom: 4rem;
        @media screen and (max-width: 576px) {
            font-size: 24px;
        }
    }
    .two-col{
        grid-gap: 2rem;
        @media screen and (max-width: 576px) {
            grid-template-columns: 1fr;
            grid-gap: 1rem;
        }
    }
    .job-description{
        grid-template-columns: 2fr 1fr;
        @media screen and (max-width: 576px) {
            grid-template-columns: 1fr;
            grid-gap: 1rem;
            margin-top:1rem;
            margin-bottom:1rem;
            input{
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
    input, select{
        border-radius: 27px;
        border: 1px solid $primary-color;
        margin-bottom: 1.5rem;
        @media screen and (max-width: 576px) {
            margin-bottom:0;
            padding-bottom:0;
        }
    }
    .service-switch, .upload-buttons{

        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
     
        @media screen and (max-width: 576px) {
            padding-left:0;
            border-left:none;
            label{
                padding:0;
                margin:0;
                &:last-child{
                    margin-left: 1rem;
                }
            }
        }
        input {  //Make the input non existent
            position: absolute !important;
            overflow: hidden;
            opacity: 0;
            pointer-events: none;
        }
        label {
            font-weight: 400;
            font-size: 18px;
            text-align: center;
            /* padding: 0px 6.5rem; */
            width: 100%;
            border: 1px solid #0C183E;
            border-radius: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            margin-top: 17px;
        }
        
        label:hover {
            cursor: pointer;
        }

        input:checked + label {
            background-color: $primary-color;
            color:white;
        }
    }
    .upload-buttons{
        label{
            margin-top: 24px;
            margin-left:0;
            padding: 1rem;
        }
        input.chosen + label{
            background-color: $primary-color;
            color:white;
        }
        .file-browse{
            display: none;
        }
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap:0 2rem;

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            
        }
        @media screen and (max-width: 576px) {
            padding-left:0;
        }
        
    }
    select{
        display: block;
        width:100%;
        height: 45px;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("../assets/arrow.svg");
        background-repeat: no-repeat;
        background-position: right 40px center;
    }
    label{
        font-weight: 700;
        margin-bottom: 10px;
        margin-left:20px;
        display:block;
        
    }
    button[type=submit]{
        border-radius: 10px;
        border: 1px solid $primary-color;
        background: rgba(12, 24, 62, 0.1);
        color:black;
        font-size: 18px;
        font-weight: 700;
        margin: 2rem auto;
        padding:2.2rem 0;
        @media screen and (max-width: 576px) {
            padding:1rem 0;
        }
    }
    button, input[type=radio] + label {
        transition: all .3s ease-in-out;
        &:hover{
            color:white;
            background: $primary-color;
        }
    }
    #form-result{
        text-align: center;
        font-size: 36px;
    }
    .form-result-msg{
        text-align: center;
        font-size: 30px;
        padding-top: 0;
        margin-top: 0;
    }
    .footer-contact{
        font-size: 26px;
        font-weight: 300;
        display:flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        @media screen and (max-width: 992px) {
            flex-direction: column;
            font-size: 3.5vw;
        }
        @media screen and (max-width: 300px) {
           
            font-size: 14px;
            white-space: nowrap;
        }
        a{
            text-decoration: none;
            color:black;
            white-space: nowrap;
        }
    }
}
.contact-card-wrapper {
    $card-width: 683px;
    $card-color: #701078;
    position: fixed;
    inset: 0;
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: flex-end;
    z-index: 100;
    overflow: hidden;
  
    .contact-card {
        background-color: #FFFFFF;
        padding: 5rem 4rem;
        border-radius: 20px 0 0 20px;
  
        transform: translate($card-width, 0px);
        transition: all .75s ease-in-out;
        width: fit-content;
        max-width: $card-width;
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        #form-result{
            font-size: 24px;
        }
        .form-result-msg{
            font-size: 18px;
        }
        .title {
            color: #701078;
            font-size: 22px;
            font-weight: 500;
            padding:0;
            margin:0;
            padding-bottom: 1rem;
            display: inline-block;
            // border-bottom:1px solid $card-color;
        }
        label{
            display: block;
            font-weight: 700;
            font-size: 14px;
            margin-left: 20px;
            margin-bottom: 10px;
        }
        input,select{
            border-radius: 27px;
            border-color:$card-color ;
            height: 60px;
            font-size:28px;
            margin-bottom: 2rem;
        }
        .two-col{
            grid-gap: 2rem;;
        }
        select{
            display: block;
            width: 100%;
            text-align: center;
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent;
            background-image: url("../assets/arrow.svg");
            background-repeat: no-repeat;
            background-position: right 40px center;
        }
        hr{
            border-color: $card-color;
            margin-bottom: 3rem;
        }
        .service-switch{
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            grid-gap: 2rem;
            padding-top: 1rem;
            padding-bottom: 2rem;
            input {  //Make the input non existent
                position: absolute !important;
                overflow: hidden;
                opacity: 0;
                pointer-events: none;
            }
            label {
                font-weight: 700;
                font-size: 18px;
                text-align: center;
                /* padding: 0px 6.5rem; */
                width: 100%;
                border: 1px solid $card-color;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                padding:0;
                margin:0;
            }
            
            label:hover {
                cursor: pointer;
            }
    
            input:checked + label {
                background-color: $card-color;
                color:white;
            }
        }
        button[type=submit]{
            border-radius: 10px;
            border: 1px solid $card-color;
            background: #FDFDFD;
            color:black;
            font-size: 18px;
            font-weight: 700;
            margin-top: 2rem;
            margin-bottom: 10px;
            padding:2.2rem 0;
        }
    }
    &.active {
        pointer-events: all;
        .contact-card {
            transform: translate(0px, 0px);
        }
    }
}
.slider{
    display: inline-flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;
    align-items: stretch;
    hr{
        max-width: 50px;
        border-bottom:2px solid white;
    }
    a {
        text-decoration: none;
        .card{
            height: 100%;
        }
    }
    .card{
        display: inline-grid;
        grid-template-columns: repeat(2, 1fr); 
        width: 770px;
        min-width: 770px;
        background-color: $primary-color;
        margin:0  1.25rem;
        img{
            height: 100%!important;
            width: 100%!important;
            object-fit: cover;
        }
        .card-content{
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding:2rem 3rem;
            color:white;
            .text{
                font-size: 16px;
                margin-top:0;
                @media screen and (max-width: 576px) {
                    font-size: 14px;
                }
            }
            .name{
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 0px;
                @media screen and (max-width: 576px) {
                    font-size: 18px;
                    white-space: nowrap;
                }
            }
            .position{
                font-size: 12px;
                font-weight: 600;
                margin:0;
            }
        }
    }
    @media screen and (max-width: 992px) {
        .card{
  
            width:70vw;
            min-width: 70vw;
            .card-content{
              
                .title{
                    font-size: 14px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        .card{
            grid-template-columns:1fr;
            width:80vw;
            min-width: 80vw;
            .card-content{
                padding:1rem 2rem;
                .title{
                    font-size: 14px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
    @media screen and (max-width: 300px) {
        .card{
            grid-template-columns:1fr;
            width:80vw;
            min-width: 240px;
            .card-content{
                padding:1rem 2rem;
                .title{
                    font-size: 14px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}
.gallery-slider{
    .glide__slides{
        height: 50vw;
        max-height: 80vh;
    }
    .glide__bullets{
        display: flex;
        justify-content: center;
        padding:2rem 0;
        .glide__bullet{
            color:transparent;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: transparent;
            border:1px solid black;
            margin:0 8px;
            &.glide__bullet--active{
                background-color: black;
            }
        }
    }
    img{
        height: 100%!important;
        object-fit: cover;
    }
}
/*============ footer ============*/
footer{
    color:white;
    .footer-upper{
        background:$primary-color;
        padding:4rem 0;
        min-height: 420px;
        @media screen and (max-width: 768px) {
            padding:2rem 1rem;
       
        }
        .footer-upper-wrapper{
            display:flex;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                .footer-logo{
                    display: none;
                }
            }
            .menu{
                display:inline-flex ;
                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                }
                li{
                    list-style: none;
                }
                & > ul{ 
                    margin:0;
                    padding:0;
                    width: fit-content;
                    display:flex;
                    justify-content: space-around;
                    @media screen and (max-width: 768px) {
                        flex-direction: column;
                        align-items: center;
                    }
                    & > li{
                        display: flex;
                        flex-direction: column;
                        margin-right: 4rem;
                        @media screen and (max-width: 768px) {
                            margin-right: 0;
                        }
                        & > a {
                            font-weight:600;
                            font-size:16px;
                            margin-bottom: 2rem;
                            @media screen and (max-width: 768px) {
                                margin-bottom: 1rem;
                            }
                        }
                        ul{
                            margin:0;
                            padding:0;
                            font-size:14px;
                            @media screen and (max-width: 768px) {
                                margin-bottom: 1rem;
                            }
                            li{
                                margin-bottom: 1rem;
                                @media screen and (max-width: 768px) {
                                    margin-bottom: 5px;
                                }
                            }
                        }
                    }
                }
                .link{
                    font-weight: 600;
                    font-size: 16px;
                }
            }
        }
     
        
    }    
    .footer-bottom{
        background:#151515;
        .footer-contact{
            font-size: 16px;
            font-weight:600;
            display:flex;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 1rem;
                text-align: center;
                .site-links{
                    margin-top: 2rem;
                }
            }
            .site-links{
                display: flex;
                align-items: center;
                .site-link{
                    transition: all .2s ease-in-out;
                    transform: scale(1);
                    &:hover{
                        transform: scale(1.2);
                    }
                }

            }
        }
    }
}

/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}


/*============ splash ============*/
/*  banner */
.banners-home .banner-stage {
    padding-top:33%;
    position:relative;
}
.banners-home .banner {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:block;
    background-position:center;
    background-size:cover;
}
.banners-home .banner-navigation {
    display:none;
}

/*============ sub page============*/
.page-content {
    padding:5px;
}
.page-graphic {
    padding-top:27.3%;
    background-size:cover;
    background-position:center;
    position:relative;
    display:block;
    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}

/*============ Contact ============*/
.contact-details.has-map,
.map-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 48%;
    box-sizing: border-box;
}
.contact-details.has-map {
    padding-right: 10px;
}

.contact-details p {
    margin: 5px 0;
}
.opening-hours {
    display: block;
}
.contact-wrapper form {
    padding-top: 20px;
}
.contact-wrapper form p {
    margin: 10px 0;
}

.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 280px;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1000px) {
    header.top{
        position: relative;
        background-color: #151515;
    }

    #mobile-menu {
        display:block;
    }
    .menu-wrapper{
        padding-right: 5rem;
        & > ul, button{
            display:none;
        }
    }
    // .header-menu,
    // .main-menu {
    //     display:none;
    // }
    .slide-menu {
        display:block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    .slide-menu-active #mobile-menu {
        right: 260px;
    }


}

@media only screen and (max-width: 760px) {

    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
/** form **/
    .contact-details.has-map,
    .map-wrap{
        width: 100%;
        padding: 0px;
    }
    .map-wrap {
        margin-top: 20px;
        height: 80vh;
    }
    #map-click {
        display: block;
    }
}
@media only screen and (max-width: 500px) {
    .header-social-links{
        display:none;
    }
}
/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}