@use 'vars' as *;

.t-home{

    .splash{
        
        .overlay{
            .contentwrap{
                z-index: 100;
            }
            .title{
                display: none; 
            }
    
            &::after{
                content: "";
                left:0;
                top:0;
                position: absolute;
                height: 100%;
                width: 100%;
                background: no-repeat url('../assets/rainbow.svg');
                background-position: bottom right;
                background-size: min(50vw, 800px);
                background-color:#0C183E5C;
                @media screen and (max-width: 992px) {
                    background-size: contain;
                    opacity: .5;
                }
            }
        }
        .tubular-container iframe{
            background-color:lighten $primary-color;
        }
    }
    .page-content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
        max-width: 1500px;
        margin:12rem auto;
        padding:2rem;
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            margin:6rem auto;
        }
        @media screen and (max-width: 576px) {
            margin:3rem auto;
        }
        .page-icon{
            text-align: right;
        }
        .content{
            font-weight: 300;
            font-size: 26px;
            max-width: 540px;
            @media screen and (max-width: 300px) {
                font-size: 18px;
            }
        }
        .links > *{
            margin-right: 1rem;
            margin-bottom: 1rem;
        }
    }
    .testimonials{
        padding:10rem 0;
        @media screen and (max-width: 576px) {
            padding-top:0;
            padding-bottom: 4rem;
        }
        .title{
            text-align: center;
            font-weight: 700;
            font-size: 22px;
            margin-bottom: 1rem;
        }
        .subtitle{
            text-align: center;
            font-weight: 700;
            font-size: 52px;
            margin-top: 0;
            margin-bottom: 4rem;
            @media screen and (max-width: 992px) {
                font-size: 5vw;
            }
            @media screen and (max-width: 576px) {
                font-size: 32px;
            }
            @media screen and (max-width: 300px) {
                font-size: 18px;
            }
        }
       
        
    }
    .services{
        padding:10rem 0;
        @media screen and (max-width: 576px) {
            padding:3rem 0;
        }
    }

    .clients{
        padding:10rem 2rem;
        .title{
            font-size: 22px;
            font-weight: 500;
        }
        .banners-clients{
            .banner-stage{
                display: flex;
                justify-content: space-between;
                align-items: stretch;

                
                a{
                    display: flex;
                    align-items: center;
                    border-right:1px solid white;
                    &:last-child{
                        border-right: none;
                    }
                }
                .banner-name{display: none;}
            }
            @media screen and (max-width: 992px) {
                .banner-stage{
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }
        @media screen and (max-width: 992px) {
            padding:6rem 2rem;
        }
        @media screen and (max-width: 576px) {
            padding:3rem 2rem;
        }
    }
   
    .career{
        height: 600px;
        position: relative;
        background: rgba(12,24,62,.7);
        color:white;
        img{
            height: 100%!important;
            width: 100%!important;
            object-fit: cover;
            mix-blend-mode: multiply;
        }
        .overlay{
            position: absolute;
            inset:0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            h1{
                font-size: 40px;
                font-weight: 400;
                max-width: 700px;
                text-align: center;
                margin-bottom: 3rem;
            }
        }  
        @media screen and (max-width: 992px) {
            
            height:60vw;
            min-height: 250px;
            .overlay{
                padding:2rem;
                h1{
                    font-size: 5vw;
                }
            }
            
        }
    }
  
}