@use 'vars' as *;

.t-about-us{
    .page-content{
        padding:10rem 0;
        font-size: 30px;
        font-weight: 300;
        @media screen and (max-width: 768px) {
            font-size: 22px;
     
        }
        @media screen and (max-width: 576px) {
            padding:3rem 2rem;
     
        }
    }
    .map-wrapper{
        width:100%;
        .map{
            max-width: none;
            width: 100%;
            background: $primary-color;
            object-fit: cover;
            height: auto;
            pointer-events: none;
            @media screen and (max-width: 576px) {
                display: none;
         
            }
        }
      
     
    }
    #team{
        padding: 0;
    }
    .hoverable{
        pointer-events: all;
        path{
            transition: all .5s ease-in;
            stroke-width: 0;
            stroke:transparent;           
            fill:transparent;
        }
      
        &:hover, &.hover{
            path{
                stroke-width: 15;
            }
        }
        &#armidale_path{
            &:hover, &.hover{
                path{
                    fill:#8ABB2A;
                    stroke:#8ABB2A;     
                }
            }
        }
        &#walcha_path{
            &:hover, &.hover{
                path{
                    fill:#76187E;
                    stroke:#76187E;
                }
            }
        }
        &#tamworth_path{
            &:hover, &.hover{
                path{
                    fill:#43558E;
                    stroke:#43558E;
                }
            }
        }
        &#gunnedah_path{
            &:hover, &.hover{
                path{
                    fill:#E50019;
                    stroke:#E50019;
                }
            }
        }
        &#narrabri_path{
            &:hover, &.hover{
                path{
                    fill:#009CDC;
                    stroke:#009CDC;
                }
            }
        }
        &#inverell_path{
            &:hover, &.hover{
                path{
                    fill:#F77E0B;
                    stroke:#F77E0B;
                }
            }
        }
        &#liverpool_plains_path{
            &:hover, &.hover{
                path{
                    fill:#F77E0B;
                    stroke:#F77E0B;
                }
            }
        }
        &#moree_plains_path{
            &:hover, &.hover{
                path{
                    fill:#E50019;
                    stroke:#E50019;
                }
            }
        }
        &#uralla_path{
            &:hover, &.hover{
                path{
                    fill:#009CDC;
                    stroke:#009CDC;
                }
            }
        }
        &#tenterfield_path{
            &:hover, &.hover{
                path{
                    fill:#8ABB2A;
                    stroke:#8ABB2A;     
                }
            }
        }
        &#glen_innes_severn_path{
            &:hover, &.hover{
                path{
                    fill:#43558E;
                    stroke:#43558E;
                }
            }
        }
        &#gwydir_path{
            &:hover, &.hover{
                path{
                    fill:#76187E;
                    stroke:#76187E;
                }
            }
        }

        
        
    }
    .service-wrapper, .team-wrapper{
        .title{
            font-size: 22px;
            font-weight: bold;
            color:$primary-color;
            @media screen and (max-width: 768px) {
                font-size: 18px;
         
            }
        }
    }
    .manager-wrapper{
        padding-bottom: 4rem;
        .title{
            font-size: 52px;
            @media screen and (max-width: 1200px) {
                font-size: 4vw;
         
            }
            @media screen and (max-width: 768px) {
                font-size: 28px;
         
            }
            @media screen and (max-width: 320px) {
                font-size: 18px;
            }
        }
        .card-wrapper{
            display:grid;
            grid-gap:2rem;
            justify-content: center;

            grid-template-columns: repeat(3, 1fr);
            @media screen and (max-width: 992px) {
                grid-template-columns: 1fr 1fr;
                text-align: center;
                justify-content: center; 
                .card:last-child:nth-child(odd) {
                    grid-column: 1 / -1;
 
                }
            }
            @media screen and (max-width: 768px) {
                grid-template-columns: 1fr;
                   
            }
            .card{
                max-width: 400px;
                margin:0 auto;
            }
            img{
                width: 100%;
            }
            .name{
                font-size:22px;
                font-weight: 500;
                margin-bottom: 0;
                text-transform: uppercase;
            }
            .position{
                font-size:14px;
                font-weight: 700;
                margin-top:.5rem;
                margin-bottom: 2rem;
            }
            .summary{
                font-size:  16px;
                font-weight: 300;
            }
        }
    }
    .team-image{
        img{
            width: 100%;
            height: auto;
            max-height: 80vw;
            object-fit: cover;
        }
        margin-bottom: 10rem;
    }
    .services{
        margin-bottom: 2rem;
    }
    .brands{
        margin-bottom: 4rem;
    }
}

