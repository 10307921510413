.t-service{
    .splash{
        .overlay {
            .content{
                .subtitle{
                    font-size: 62px;
                    font-weight: 700;
                    @media screen and (max-width: 992px) {
                        font-size: 48px;   
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 32px;
                 
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 24px;
                    }
                }
            }
         
        }
       
    }
    .page-content{
        padding:10rem 0;
        font-size: 30px;
        font-weight: 300;
        @media screen and (max-width: 768px) {
            font-size: 22px;
            padding:5rem 2rem;
        }
        @media screen and (max-width: 576px) {
            font-size: 18px;
            padding:3rem 2rem;
        }
        h4{
            font-size: 22px;
        }
    }
    .two-col{
        grid-gap:2rem 3rem;
        margin:0;
        padding:0;
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            grid-gap:1.5rem
        }
        @media screen and (max-width: 300px) {
            .pill{
                padding:1rem;
            }
        }
    }
    .slider{
 
        .card{
            .title{
                margin-top:10px;
                font-size: 36px;
                font-weight: 700;
            }
        }
    }
    .glide{
        margin-top:18rem;
    }
    .related-content-title{
        font-size:22px;
        font-weight: bold;
        margin-bottom: 6rem;
        @media screen and (max-width: 576px) {
            margin-bottom: 3rem;
        }
    }
    .services{
        position: relative;
        padding:10rem 0;
        @media screen and (max-width: 576px) {
            padding:3rem 0;
        }
        .card{
            height: 420px;
            @media screen and (max-width: 576px) {
                height: 40vw;
            }
        }
    }
}