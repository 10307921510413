@use 'vars' as *;

.t-products{
    .splash{
        .overlay {
            .content{
                .subtitle{
                    font-size: 62px;
                    font-weight: 700;
                    @media screen and (max-width: 768px) {
                        font-size: 6vw;
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 32px;
                    }
                }
            }
        }
       
    }
    .page-content{
        .two-col{
            grid-gap:2rem;
            padding:10rem 0;
            @media screen and (max-width: 992px) {
                padding:6rem 0;
                grid-template-columns: 1fr;
            }
            @media screen and (max-width: 768px) {
                padding:4rem 0;
            }
            @media screen and (max-width: 576px) {
                padding:2rem 0;
            }
        }
        .card{
            width:100%;
            padding-bottom: 100%;
            position: relative;
            img{
                position: absolute;
                width: 100%;
                height: 100%!important;
                object-fit: cover;
            }
            .overlay{
                position: absolute;
                min-height: 170px;
                right:0;
                left:0;
                bottom:0;
                background-color: rgba(254, 254, 254, .75) ;
                display:grid;
                grid-template-columns: .7fr .3fr;
                padding:2rem;
                grid-gap:3rem;
                @media screen and (max-width: 992px) {
                    grid-template-columns: 1fr;
                    grid-gap:1rem;
                }
           
                .text{
                    .title{
                        font-size:22px;
                        font-weight: 700;
                        margin-top:0;
                        margin-bottom: 1rem;
                    }
                    .summary{
                        font-size: 14px;
                    }
                }
                .link{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    a{
                        text-decoration: none;
                    }
                }
                
            }
            @media screen and (max-width: 576px) {
                padding-bottom: 2rem;
                border:1px solid #ddd;
                img{
                    position: static;
                    aspect-ratio: 1/1;
                    height: auto!important;
                }
                .overlay{
                    position: static;
                    padding:1rem 1.5rem;
                }
         
            }
        }
    }
}
.t-product{
    .splash{
        .overlay {
            background-color: #0C183E61;
            .content{
                .title{
                    font-weight:500;
                }
                .summary{
                    font-size: 58px;
                    font-weight: 700;
                    @media screen and (max-width: 996px) {
                        font-size: 6vw;
                 
                    }
                    @media screen and (max-width: 576px) {
                        font-size: 5vw;
                    }
                    @media screen and (max-width: 300px) {
                        font-size: 24px;
                    }
                }
            }
        }
       
    }
    .page-content{
        .page-header{
            display: flex;
            border-bottom: 1px solid $primary-color;
            font-size: 18px;
            margin-bottom: 1rem;
            .title{
                margin-right: 2rem;
                font-weight: 400;
                margin-bottom: 1rem;
            }
            .data{
                margin-right: 4rem;
                font-weight: 700;
                color: $primary-color;
                margin-bottom: 1rem;
            } 
            @media screen and (max-width: 576px) {
                display:grid;
                grid-template-columns: 1fr 1fr;
                padding-bottom: 2rem;
            }
        }
        .content{
            font-size: 18px;
            font-weight: 300;
            @media screen and (max-width: 576px) {
                font-size: 16px;
            }
            h2{
                font-size: 40px;
                @media screen and (max-width: 576px) {
                    font-size: 34px;
                }
            }
        }
    }
}